:root {
  --neutral-600: #777990;
  --neutral-800: #14142b;
  --secondary-1: #ebf8ff;
  --neutral-700: #4e4b66;
  --secondary-2: #fff5d2;
  --secondary-3: #f3fbff;
  --secondary-4: #f3eeff;
  --neutral-500: #b2b4c4;
  --neutral-400: #d9dbe9;
  --neutral-300: #eff0f6;
  --neutral-200: #f6f7ff;
  --neutral-100: white;
}
body {
  color: var(--neutral-600);
  font-family: Satoshi, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.777em;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}
html,
body,
div {
  scroll-behavior: smooth;
}
.container-default {
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  transition-duration: 0.2s;
}
.container-default-child {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 592px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}
.text-center {
  text-align: center;
}
h1 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 64px;
  font-weight: 500;
  line-height: 1.3em;
}
h2 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.38em;
}
h3 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.333em;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  letter-spacing: 0.015em;
}
a {
  color: var(--neutral-800);
  text-decoration: underline;
  transition: color 0.3s;
}
img {
  max-width: 100%;
  display: inline-block;
}
.btn {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.btn-primary {
  background-color: var(--neutral-800);
  color: var(--neutral-100);
  text-align: center;
  transform-style: preserve-3d;
  border-style: none;
  border-radius: 80px;
  padding: 24px 60px;
  font-size: 18px;
  font-weight: 700;
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}
.btn-secondary {
  background-color: var(--neutral-100);
  color: var(--neutral-800);
  border: 1px solid var(--neutral-800);
}
.section {
  padding-top: 100px;
  padding-bottom: 100px;
}
.flex {
  display: flex;
}
.v-center {
  align-items: center;
}

.t-hover-fade {
  transition-duration: 0.4s;
}
.t-hover-fade:hover {
  color: var(--neutral-600);
  cursor: pointer;
}

.bg.card-square {
  width: 100%;
  max-width: 900px;
  min-height: 116%;
  background-color: var(--neutral-200);
  border-radius: 24px;
  margin-top: -40px;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: auto;
}
.bg {
  z-index: -1;
  position: absolute;
}
.grid-container {
  display: flex;
  gap: 48px;
  flex-wrap: wrap;
  width: 100%;
}
.pd-top-bottom-200px {
  padding-bottom: 200px;
  padding-top: 200px;
  overflow: hidden;
}
.hc-flex.read-case-study {
  color: var(--neutral-800);
  font-weight: 700;
}

.hc-flex {
  align-items: center;
  line-height: 1em;
  display: flex;
}
.arrow-right {
  font-size: 25px;
  margin-top: 2px;
  margin-left: 8px;
}

.hidden-scroll-wrapper {
  width: 100%;
  overflow: hidden;
  height: 650px;
  position: relative;
}
.hidden-scroll-child {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: -17px;
  right: 0;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.split {
  justify-content: space-between;
  display: flex;
}
.section.pd-top-bottom-150px {
  padding-top: 150px;
  padding-bottom: 150px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #eef0f5;
}

.portfolio-project-content ul {
  margin-left: 20px;
  margin-top: 10px;
}
.portfolio-project-content ul li {
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }
  h1 {
    margin-bottom: 20px;
    font-size: 54px;
  }
  h2 {
    font-size: 35px;
  }
  .split-content.hero-left {
    max-width: 880px;
    min-width: auto;
    text-align: center;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  .container-default {
    flex-direction: column;
    align-items: stretch;
  }
  .hero-section .left,
  .hero-section .right {
    width: 100%;
    padding: 24px;
    min-width: 0 !important;
    max-width: 100% !important;
    text-align: center;
  }

  html,
  body {
    width: 100%;
    overflow-x: hidden !important;
  }
  .nav-header {
    overflow: hidden !important;
    height: 90px;
    margin-bottom: 0 !important;

    transition-duration: 0.4s;
  }

  .header-logo-main {
    position: absolute;
    top: 0;
    left: 20px;
    height: 100px !important;
  }
  .header-contact-email {
    display: none;
  }
  .header-expand-button {
    display: flex !important;
  }
  .nav-header a {
    font-size: 16px;
  }
  .nav-header ul {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid var(--neutral-400);
  }
  .nav-header ul li {
    margin: 10px !important;
  }

  .hero-section {
    padding-top: 0 !important;
    padding-bottom: 50px !important;
  }
  /* .btn {
    display: none;
  } */
  .hero-section .left {
    margin-right: 0 !important;
  }

  .hero-section .right {
    margin-top: 40px;
  }
  .hero-section .hero-underline {
    top: -27px;
    max-width: 80%;
    z-index: 1;
  }
  .hero-section .title {
    z-index: 2 !important;
    position: relative;
  }
  .grid-container .card-container {
    width: 120%;
    margin-left: -25px;
    margin-right: -25px;
    border-radius: 0;
    flex-direction: column;
    padding: 20px 20px;
  }
  .grid-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
  .grid-container .card-container .card-icon {
    margin-right: 0;
  }
  .grid-container .card-container .card-title {
    margin-top: 25px;
  }

  .about-wrapper {
    flex-direction: column-reverse;
  }
  .about-paragraph {
    margin-bottom: 24px !important;
  }
  .about-wrapper .image-wrapper,
  .about-wrapper .about-details {
    width: 100% !important;
  }
  .top-content-resume {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .image.resume-underline {
    max-width: 30%;
    top: 27px !important;
    left: 7em !important;
  }
  .image.portfolio-underline {
    max-width: 70%;
  }

  .slider-main-wrapper .scroll-buttons {
    left: 24px;
    right: auto;
    top: 150px !important;
  }
  .slider-main-wrapper .scroll-buttons > div {
    max-width: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
    max-height: 70px !important;
  }
  .image.portfolio-card {
    width: 100% !important;
    height: 100% !important;
  }
  .portfolio-card-container {
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .hidden-scroll-wrapper {
    height: 550px;
  }
  .slider-nav-from-top {
    margin-top: 60px;
  }

  .image.skills-underline {
    max-width: 50%;
    top: 2.2em !important;
  }
  ._3-column-grid.skills-grid {
    grid-template-columns: 1fr;
    margin-top: -50px;
    margin-bottom: -50px;
  }
  .card.skill-card {
    padding-bottom: 57px;
    padding-top: 57px;
  }

  .card.skill-card:nth-child(2) {
    border-right: none !important;
    border-left: none !important;

    border-top: 1px solid var(--neutral-400);
    border-bottom: 1px solid var(--neutral-400);
  }

  .image-wrapper.portfolio-card {
    margin-bottom: 25px !important;
  }
  .portfolio-hero {
    width: 85%;
  }
  .title.portfolio-title {
    margin-bottom: 30px !important;
  }
  .title.h4-size {
    font-size: 18px !important;
  }
  .split {
    flex-wrap: wrap;
  }
  .about-portfolio-project {
    /* justify-content: space-evenly; */
  }
  .about-portfolio-project-item {
    margin-bottom: 20px;
    flex: 1;
    max-width: 45%;
    min-width: 45%;
  }
  .image.portfolio-project-main-image {
    margin-top: 30px !important;
  }
  .section.pd-top-bottom-150px,
  .section {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .portfolio-project-container {
    flex-direction: column;
  }
  .card.portfolio-project-title {
    width: 100%;
    margin-bottom: 0 !important;
    position: static !important;
  }
  .w-richtext figure img {
    width: 100%;
  }
  .split-content.portfolio-project-content {
    width: 100% !important;
  }
  .split-content.portfolio-project-content.reduced-margin {
    margin-bottom: 0 !important;
  }

  .bg.card-square {
    max-width: 100vw;
    min-height: 106%;
    border-radius: 12px;
  }
  .btn.btn-primary {
    padding: 12px 36px;
    font-size: 16px;
    font-weight: 600;
  }
  .footer .links a {
    margin: 0 10px !important;
  }
  .footer .social-icons {
    font-size: 24px !important;
  }
  .portfolio-page-card-wrapper {
    margin-bottom: 50px;
  }
  .about-page-section .container-default {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .about-page-section .about-wrapper {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    margin-bottom: 15px;
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  body {
    font-size: 16px;
    font-weight: 400;
  }
  .section {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}

.animate__tada,
.animate__heartBeat,
.animate__pulse {
  animation-iteration-count: infinite;
}
