.portfolio-card-container {
  min-width: 575px;
  max-width: 575px;
  overflow: hidden;
}
.image.portfolio-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.3s;
  border-radius: 24px;
}
.image-wrapper.portfolio-card {
  border-radius: 24px;
  margin-bottom: 52px;

  position: relative;
  overflow: hidden;
}
.portfolio-card-container a {
  text-decoration: none;
}
.portfolio-section-header {
  margin-bottom: 80px;
}
.portfolio-section-home {
  padding-bottom: 100px;
}
.image.portfolio-card:hover {
  transform: scale(1.1);
}
.portfolio-project-website-link {
  font-weight: 500;
}
.portfolio-project-website-link:hover {
  transition: opacity 0.3s;
}
