.footer {
    border-top: 1px solid var(--neutral-400);
    background-color: #fff;
    padding-top: 0;
    margin-top: 0;

    padding-top: 50px;
}
.footer .links {
  margin-top: 30px;
}
.footer .links a {
  margin: 0 10px;
}
.footer .social-icons {
  font-size: 28px;
}