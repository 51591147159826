.container-medium-838px {
  max-width: 838px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}
.contact-form {
  margin-top: 70px;
  min-height: 732px;
  border: 1px solid var(--neutral-400);
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 56px;
  padding: 78px 62px;
  display: flex;
  overflow: hidden;
}
._2-column-grid.contact-form-grid {
  grid-template-rows: auto;
}
.contact-form-grid {
  grid-column-gap: 40px;
  grid-row-gap: 24px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.field-label {
  color: var(--neutral-800);
  margin-bottom: 17px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.input {
  min-height: 70px;
  border: 1px solid var(--neutral-400);
  color: var(--neutral-600);
  border-radius: 500px;
  margin-bottom: 0;
  padding: 25px 27px;
  font-size: 18px !important;
  line-height: 1em;
  transition: border-color 0.3s;
}
.w-input,
.w-select {
  width: 100%;
  height: 38px;
  color: #333;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

textarea.w-input,
textarea.w-select {
  height: auto;
}
.text-area {
  max-height: 200px;
  max-width: 100%;
  min-height: 126px;
  min-width: 100%;
  border: 1px solid var(--neutral-400);
  color: var(--neutral-600);
  border-radius: 20px;
  margin-bottom: 0;
  padding: 25px 27px;
  font-size: 18px;
  transition: border-width 0.3s, border-color 0.3s;
}
.input-wrapper.message {
  max-width: 100%;
  grid-area: span 1 / span 2 / span 1 / span 2;
  justify-self: stretch;
}

@media screen and (max-width: 991px) {
  .contact-form {
    align-self: stretch;
    padding: 50px 40px;
  }
  ._2-column-grid.contact-form-grid {
    grid-column-gap: 24px;
    grid-row-gap: 32px;
    grid-template-columns: minmax(100%, 100%);
  }
  .input {
    min-height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .input-wrapper {
    max-width: 100%;
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: stretch;
  }

  .input,
  .text-area {
    font-size: 14px !important;
  }
}
