.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 32px 0;
  position: relative;
}
.nav-header ul {
  list-style-type: none;
}
.nav-header ul li {
  display: inline-block;
  margin: 20px;
}
.nav-header .mail-icon {
  margin-right: 12px;
}
.nav-header a {
  text-decoration: none;
}
.main-title-nav-header {
  border: 3px solid #000;
  text-align: center;
  padding: 10px 30px 10px 30px;
}
.header-expand-button {
  position: absolute;
  top: 28.5px;
  right: 10px;
  background-color: #000;
  /* padding: 15px; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
}
.header-logo-main {
  height: 150px;
  /* display: none; */
}
.nav-header {
  margin-bottom: -60px;
}
.menu-icon-header {
  font-size: 30px;
  color: #fff;
}
.nav-active-item {
  opacity: 0.7;
}


.nav-header .social-icons {
  margin: 0 5px;
}
.nav-header .social-icons {
  font-size: 26px;
}