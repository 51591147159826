.skills-section {
  margin-top: 100px;
  padding-bottom: 0;
}
.container-small.my-skills {
  position: relative;
  margin-bottom: 60px;
}
.title.my-skills {
  margin-bottom: 43px;
}
.image.skills-underline {
  z-index: -1;
  position: absolute;
  top: 3em;
  bottom: auto;
  left: auto;
  right: 6em;
}

._3-column-grid.skills-grid {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
._3-column-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.card.skill-card {
    border-radius: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.card.skill-card:nth-child(2){
      border-right: 1px solid var(--neutral-400);
    border-left: 1px solid var(--neutral-400);
}

.image.skill-icon {
    width: 76px;
    min-height: 76px;
    border-radius: 20px;
    margin-bottom: 32px;
}

.title.skill-name {
    margin-bottom: 28px;
}

.title.skill {
    margin-bottom: 14px;
}