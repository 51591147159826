.slider-nav-from-top {
  display: flex;
  gap: 34px;
  /* overflow-x: scroll; */
  width: 100%;
}
.slider-main-wrapper .scroll-buttons {
  position: absolute;
  right: 270px;
  top: 220px;
  z-index: 1;
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.chevron-slider-icon {
  font-size: 30px;
}
.slider-portfolio-wrapper-main {
  position: relative;
}
.slider-main-wrapper .scroll-buttons > div {
  z-index: 10;
  width: 80px;
  max-height: 80px;
  min-height: 80px;
  min-width: 80px;
  background-color: var(--neutral-300);
  color: var(--neutral-800);
  transform-style: preserve-3d;
  border-radius: 50%;
  font-size: 27px;
  line-height: 1em;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
}
.slider-main-wrapper .scroll-buttons > div:hover {
  background-color: black;
  color: #fff;
}
.slider-main-wrapper .hidden-scroll-wrapper {
  padding-right: 20px;
}
