.image.portfolio-underline {
    z-index: -1;
    position: absolute;
    top: auto;
    bottom: -20px;
    left: 0;
    right: 0;
}
.portfolio-section-header{
  position: relative;
}