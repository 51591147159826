.about-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.about-wrapper .image-wrapper {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  width: 50%;
  border-radius: 24px;
  overflow: hidden;
}
.about-wrapper .image-wrapper img {
  border-radius: 24px;
}
.about-details {
  margin-bottom: 24px;
  width: 45%;
}
.about-paragraph {
  margin-bottom: 56px;
}
.carousel-about-div img {
  object-fit: cover;
  max-height: 600px;
}
.thumbs .thumb {
  max-height: 80px;
  /* display: none; */
}
.thumbs-wrapper,
.carousel-status {
  display: none;
}
