.card-container{
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
    
    align-items: flex-start;
    padding: 48px 48px 48px 40px;
    display: flex;
    width: 48%;

    background-color: var(--neutral-100);
    border-radius: 20px;
    overflow: hidden;
}
.image.card-icon{
    border-radius: 20px;
    margin-right: 28px;
}
.image.card-company-logo{
  width: 100px;
  min-height: 15px;
  margin-right: 12px;
}
.title.card-title {
    margin-bottom: 0;
}

.paragraph.card-paragraph {
    margin-bottom: 0;
}

.card-flex-title{
  display: flex;
  justify-content: space-between;
}
.top-right-icon{
  color: var(--neutral-600);
}
.card-container .company-wrapper{
  margin-bottom: 22px;
}
.card-container .company-wrapper a {
  display: flex;
  align-items: flex-start;
}