.top-content-resume {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image.resume-underline {
    z-index: -1;
    position: absolute;
    top: 45px;
    left: 14em;
    right: auto;
}