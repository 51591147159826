.hero-section {
  padding: 70px 0 100px 0;
  display: flex;
  align-items: center;
}
.hero-section .subtitle {
  color: var(--neutral-700);
}
.hero-section .left {
  width: 100%;
  max-width: 640px;
  min-width: 520px;
  margin-right: 24px;
  position: relative;
}
.hero-section .right {
  width: 100%;
  max-width: 590px;
}
.hero-section .right img {
  max-width: 100%;
}
img.hero-underline {
  z-index: -1;
  position: relative;
  top: -38px;
}
.hero-image-home{
  /* transform: scale(1.25); */
  height: 600px;
  width: 600px;
  object-fit: cover;
  border-radius: 10px;
  filter: grayscale(1);
  opacity: .4;
  transition-duration: 1s;
  cursor: pointer;
}

.hero-image-home:hover {
  filter: grayscale(0);
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .hero-image-home{
    object-position: top;
    width: 100%;
    height: 370px;
  }
}