.section.portfolio-hero {
  padding-left: 0;
  padding-right: 0;
}
.articles-news-content-bottom-divider {
    width: 24px;
    max-height: 1px;
    min-height: 1px;
    background-color: var(--neutral-400);
    margin-left: 12px;
    margin-right: 12px;
}
.top-company-date{
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 36px;
  display: flex;
}
.title.portfolio-title{
  margin-bottom: 77px;
}
.title.about-portfolio-project-title {
    margin-bottom: 12px;
}
.title.h4-size {
    color: var(--neutral-800);
    font-size: 22px;
    font-weight: 700;
    line-height: 1.272em;
}
.image.portfolio-project-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
    margin-top: 80px;
}

.portfolio-project-container {
    max-width: 1123px;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
}
.split-content.portfolio-project-content.reduced-margin {
    margin-bottom: -80px;
}
.split-content.portfolio-project-content {
    width: 75%;
}
.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.rich-text figure {
    margin-bottom: 80px;
}
.w-richtext figure {
    max-width: 60%;
    position: relative;
}
figure {
    margin-bottom: 10px;
}
figure {
    margin: 0 0 10px;
}
.rich-text img {
    border-radius: 24px;
    margin-top: 10px;
}

.card.portfolio-project-title {
    width: 50%;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
}

.card {
    background-color: var(--neutral-100);
    border-radius: 20px;
    overflow: hidden;
}

.animatedhover {
  animation-play-state: paused;
}
.animatedhover:hover {
  animation-play-state: running;
}
.wrap-links {
    display: flex;
    flex-wrap: wrap;
}
.wrap-links > a {
    margin-right: 5px;
}